@font-face {
  font-family: 'FFMark';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url('/font/5423411/41c8162d-c2a6-49b0-8139-8bebab187588.woff2')
      format('woff2'),
    url('/font/5423411/4fe94f2e-7892-4785-9663-0350a7adf8c0.woff')
      format('woff');
}

@font-face {
  font-family: 'FFMark';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('/font/5423483/ec598369-4d91-4a16-9db3-6654f68d3c28.woff2')
      format('woff2'),
    url('/font/5423483/90214997-3760-4b80-a23b-fec02ab55fac.woff')
      format('woff');
}

@font-face {
  font-family: 'UKNumberPlate';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('/font/uknumberplate/UKNumberPlate.woff2') format('woff2'),
    url('/font/uknumberplate/UKNumberPlate.woff') format('woff');
}
