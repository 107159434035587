
        @use 'sass:math';
        @import '~backline-mixins/src/backline-mixins';
        @import 'settings';
      
.StripeElementsBase {
  display: block;
  width: 100%;
  font-family: inherit;
  font-weight: 400;
  font-size: 16px;
  background-color: $color-white;
  transition: all $duration-standard $easing-standard;
  padding: spacing(1.5) - 1 spacing(2) - 1;
  color: inherit;
  line-height: spacing(3);
  border: 1px solid $color-coolgrey-l70;
  background-clip: padding-box;
  border-radius: $radius-small;
  caret-color: $color-blue;

  &:hover {
    transition-duration: $duration-shortest;
    border-color: $color-coolgrey-l60;
  }
}

.focus {
  border-color: $color-blue;
  transition-duration: $duration-shortest;
  box-shadow: 0 0 0 4px rgba($color-blue, 0.3),
    0 0 0 rgba($color-coolgrey-l90, 0.5) inset;
  outline: 0;
}

.invalid {
  border-color: $color-status-error !important;
}
